import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import UserView from '../views/UserView.vue'
import DetailView from '../views/DetailView.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'user',
  //   component: UserView
  // },
  {
    path: '/:code?',
    name: 'detail',
    component: DetailView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
