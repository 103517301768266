<template>
    <div id="App" class="logo-wrapper">
        <!-- <h2>{{ $route.params.name }}</h2> -->
        <img src="../assets/header.png" width="200" class="gbr">

        <h2>PT QUINNOS </h2>
        <div class="btn-item">
            <button @click="openInNewTab($data.web)"> <font-awesome-icon icon="fa-solid fa-globe" />
                Website</button>
        </div>
        <div class="btn-item">
            <button @click="openInNewTab($data.email)"> <font-awesome-icon
                    icon="fa-solid fa-envelope" /> Email</button>
        </div>
        <div class="btn-item">
            <button @click="openInNewTab($data.wa)"> <font-awesome-icon
                    icon="fa-brands fa-whatsapp" />
                WhatsApp</button>
        </div>
        <div class="btn-item">
            <button @click="openInNewTab($data.ig)"> <font-awesome-icon
                    icon="fa-brands fa-instagram" />
                Instagram</button>
        </div>
        <div class="btn-item">
            <button @click="openInNewTab($data.twt)"><font-awesome-icon
                    icon="fa-brands fa-x-twitter" />
                Twitter</button>
        </div>

        <!-- Personal Contact -->
        <div v-for="(user, index) in users" :key="user.params">
            <div v-if="matchParams(user.params)">
                <h2 class="txt">Personal Contact</h2>
                <div class="btn-item">
                <button @click="openInNewTab($data.users[index].wa)"> <font-awesome-icon
                    icon="fa-brands fa-whatsapp" />
                    WhatsApp</button>
            </div>
            <div class="btn-item">
                <button @click="openInNewTab($data.users[index].email)"> <font-awesome-icon
                    icon="fa-solid fa-envelope" />
                    Email</button>
            </div>
            </div>
        </div>



    </div>
</template>
<script>
export default {

    name: "PtQuinnos",
    data() {
        return {
            tampil: true,
            web : 'https://quinnos.co.id/',
            email : 'mailto:info@quinnos.co.id',
            wa : 'https://wa.me/+6287817504424',
            ig : 'https://www.instagram.com/quinnos.id/',
            twt : 'https://twitter.com/quinnosid',
            users: [
                { params: 'q-001', name: 'anton', wa: 'https://wa.me/+6289696212224', email: 'mailto:anton@quinnos.co.id'},
                { params: 'q-002', name: 'benny', wa: 'https://wa.me/+6287823692383', email: 'mailto:benny@quinnos.co.id'},
                { params: 'q-003', name: 'yosep', wa: 'https://wa.me/+6285974744363', email: 'mailto:yosep@quinnos.co.id' },
                { params: 'q-004', name: 'ernest', wa: 'https://wa.me/+6285892285289', email: 'mailto:ernest@quinnos.co.id'},
                // { params: 'q-005', name: 'null' , wa: ' ', email :''},
                { params: 'q-006', name: 'ananda' , wa: 'https://wa.me/+6289697269723', email:'mailto:ananda@quinnos.co.id' },
                { params: 'q-007', name: 'elvia' , wa: 'https://wa.me/+6282124990927', email : 'mailto:elvia@quinnos.co.id'}
            ]
        }
    },
    methods: {
        openInNewTab(url) {
            window.open(url);
        },
        matchParams(userCode) {
            // Get params from URL
            let urlParams = new URLSearchParams(window.location.search);
            if(!urlParams.has('code')) return
            return urlParams.get('code') == userCode
        }
    },

};
</script>
<style scoped>
.logo-wrapper {
    width: 70%;
    overflow: hidden;
    margin: auto;
}

.btn-item {
    margin-top: 2%;
}

button {
    background-color: white;
    /* color: black; */
    border: 2px solid black;
    transition-duration: 0.4s;
    border-radius: 10px;
    font-size: 12px;
    padding: 12px 90px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

button:hover {
    background-color: black;
    color: white;
}

txt {
    text-size-adjust: 12;
}

@media screen and (max-width: 780px) {
    .gbr {
        margin-top: 45px;
    }

    .btn-item {
        margin-top: 6%;
    }

    button {
        background-color: white;
        /* color: black; */
        border: 2px solid black;
        transition-duration: 0.4s;
        border-radius: 10px;
        font-size: 12px;
        padding: 12px 90px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    button:hover {
        background-color: black;
        color: white;
    }

    txt {
        text-size-adjust: 12;
    }

}
</style>
  